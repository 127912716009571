export const tabs = [
  {
    id: 1,
    label: "Routing & Labor",
    icon: "Settings",
  },
  {
    id: 2,
    label: "Work Sheet",
    icon: "FileText",
  },
  {
    id: 3,
    label: "Files",
    icon: "Archive",
  },
  {
    id: 4,
    label: "Part History",
    icon: "Settings",
  },
  {
    id: 5,
    label: "Reporting",
    icon: "TrendingUp",
  },
];
