import React, { useState } from "react";
import { TableBody, TableRow, TableCell, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { stringToAlphabetSum } from "../../helpers/userImageColor";
import { getDateData } from "../../helpers/dateHelper";
import { getDollarValue } from "../../helpers/currency";
import { UserDetailIcon } from "../UserDetailIcon";

import HubTableSubRow from "./HubTableSubRow";
import { HubIcon } from "../HubIcon";

export const UserChip = ({ contact }) => {
  if (!contact?.name) return <></>;

  const { name = "", email = "" } = contact;
  return name || email ? (
    <UserDetailIcon
      user={{
        name,
        email,
        hueShift: stringToAlphabetSum(
          email && name ? email + name : name || email
        ),
      }}
      AvatarInitialProps={{ sx: { fontSize: 10 } }}
      includeThumbnail={false}
      includeEmail={email}
      includeName={name}
      includeInital
      size={25}
      variant="rounded"
    />
  ) : (
    <></>
  );
};

const HubTableBody = ({
  items = [],
  keys = [],
  condition = () => {},
  ContextMenu,
  ContextMenuProps,
  RenderElement,
  includeCheckbox,
  includeChevron,
  onRowClick,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleNav = (e, route, id) => {
    handleEvent(e);
    navigate(`/${route}/${id}`);
  };

  const getCellProps = (key, item) => ({
    ...(key?.sx ? { sx: key.sx } : {}),
    ...(key?.variant ? { variant: key.variant } : {}),
    ...(key?.onClick ? { onClick: () => key.onClick(item) } : {}),
    ...(key?.navigate
      ? { onClick: (e) => handleNav(e, key.route, item[key.id]) }
      : {}),
    ...(key?.isCheckbox ? { padding: "checkbox" } : {}),
  });

  const conditionalFormatting = (item) => condition(item);

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const processValue = (item, key) => {
    const value = getNestedValue(item, key.value) || key?.defaultValue;
    const CustomComponent = key?.CustomComponent;

    if (CustomComponent && React.isValidElement(<CustomComponent />))
      return <CustomComponent item={item} />;
    if (key.isUser) return <UserChip contact={value} />;
    if (key.isCheckbox)
      return <Checkbox checked={value} sx={{ padding: 0 }} disabled />;
    if (!value && typeof value !== "number") return value;
    if (key.isCurrency) return getDollarValue(value);
    if (key.isDate)
      return getDateData({
        startDate: value,
        includeYear: true,
      });
    if (key.isDateTime) {
      return getDateData({
        startDate: value,
        includeYear: true,
        includeTime: true,
      });
    }

    return value;
  };

  const handleRowClick = (e, item, idx) => {
    onRowClick && onRowClick(e, item);
    RenderElement && setOpen(open === idx ? null : idx);
  };

  const length =
    keys.length + (includeCheckbox ? 1 : 0) + (includeChevron ? 1 : 0);
  return (
    <TableBody>
      {items?.map((item, index) => {
        return (
          <>
            <TableRow
              key={index}
              {...conditionalFormatting(item)}
              onClick={(e) => handleRowClick(e, item, index)}
            >
              {includeCheckbox && (
                <TableCell variant="small">
                  <Checkbox sx={{ padding: 0 }} />
                </TableCell>
              )}
              {includeChevron && (
                <TableCell variant="small">
                  <HubIcon
                    icon={open === index ? "ChevronDown" : "ChevronRight"}
                    size="12px"
                  />
                </TableCell>
              )}
              {keys.map((key) => (
                <TableCell {...getCellProps(key, item)}>
                  {processValue(item, key)}
                </TableCell>
              ))}
              {ContextMenu && (
                <TableCell padding="checkbox">
                  <ContextMenu item={item} {...ContextMenuProps} />
                </TableCell>
              )}
            </TableRow>
            {RenderElement && (
              <HubTableSubRow
                isOpen={open === index && open !== null}
                item={item}
                length={length}
                RenderElement={RenderElement}
              />
            )}
          </>
        );
      })}
    </TableBody>
  );
};

export default HubTableBody;
