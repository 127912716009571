import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

import { HubButton } from "../../components";

export const StyledHubButton = styled(HubButton)(({ theme }) => ({
  fontSize: "12px",
  ".MuiStack-root": {
    gap: "5px",
    flexDirection: "row-reverse",
    ".MuiTypography-root": {
      fontWeight: 400,
      // color: theme.palette.hubColors.greyDark,
    },
  },
  svg: {
    width: "12px",
    height: "12px",
  },
}));

export const StyledCardStack = styled(Stack)({
  gap: "10px",
  height: "100%",
  overflow: "auto",
  padding: "15px 15px 15px 0px",
  "::-webkit-scrollbar": {
    display: "none",
  },
});

export const StyleDisplayStack = styled(Stack)({
  display: "grid",
  gridTemplateColumns: "175px auto 250px",
  height: "calc(100% - 51px);",
  overflow: "hidden",
});
