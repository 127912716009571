import { createSelector } from "reselect";
import { generateUniqueRandomString } from "../helpers/string";

const LOAD_ROUTINGS = "LOAD_ROUTINGS";
const CLEAR_ROUTINGS = "CLEAR_ROUTINGS";
const SET_ROUTINGS = "SET_ROUTINGS";
const ADJUST_ROUTING = "ADJUST_ROUTING";
const SET_PART_ROUTING_SUBMIT = "SET_PART_ROUTING_SUBMIT";

const ADD_DIP = "ADD_DIP";
const REMOVE_DIP = "REMOVE_DIP";

const ADD_OUTSIDE_PROCESS = "ADD_OUTSIDE_PROCESS";
const REMOVE_OUTSIDE_PROCESS = "REMOVE_OUTSIDE_PROCESS";

const ADD_POST = "ADD_POST";
const REMOVE_POST = "REMOVE_POST";

const EDIT_PART_DIP = "EDIT_PART_DIP";
const EDIT_PART_POST = "EDIT_PART_POST";
const SET_PART_POST = "SET_PART_POST";

const EDIT_PART_OUTSIDE = "EDIT_PART_OUTSIDE";

const initialState = {
  material: 0,
  tree: 0,
  ppt: 0,
  weight: 0,
  price: 0,

  routings: [],
  wax: {},
  assembly: {},
  dip: {},
  foundry: {},
  post: {},
  outside: {},

  submitting: false,
};

const findItemByIndex = ({ items = [], idx }) =>
  items.length >= idx ? idx : -1;

export const removeItemHelper = (idx, items) => {
  const index = findItemByIndex({ items, idx });
  if (index === -1) return items;
  const newItems = [...items.slice(0, index), ...items.slice(index + 1)];
  return newItems;
};

const updateItemHelper = (idx, items = [], data = {}) => {
  const index = findItemByIndex({ items, idx });
  if (index === -1) return items;

  const updatedItem = { ...items[index], ...data };
  const newItems = [
    ...items.slice(0, index),
    updatedItem,
    ...items.slice(index + 1),
  ];

  return newItems;
};

const templateDip = { leadTime: 1440 };
const templatePost = { leadTime: 1440 };
const templateOutside = { leadTime: 1440 };

const handleAddTemplate = (items = [], type) => {
  let template;
  switch (type) {
    case "dip":
      template = templateDip;
      break;
    case "post":
      template = templatePost;
      break;
    case "outside":
      template = templateOutside;
      break;
    default:
      break;
  }

  if (!template) return items;

  const newItems = [
    ...items,
    { ...template, id: generateUniqueRandomString() },
  ];
  return newItems;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FLUSH":
    case CLEAR_ROUTINGS: {
      return initialState;
    }

    case LOAD_ROUTINGS: {
      const newState = {
        ...state,
        partName: action?.data?.partName,
        partNumber: action?.data?.partNumber,
        material: action?.data?.material,
        tree: action?.data?.tree,
        ppt: action?.data?.ppt,
        weight: action?.data?.weight,
        price: action?.data?.price,
        createdAt: action?.data?.createdAt,

        routings: action?.data?.routings || [],
        wax: action?.data?.wax,
        assembly: action?.data?.assembly,
        autoclave: action?.data?.autoclave,
        dip: action?.data?.dip,
        foundry: action?.data?.foundry,
        post: action?.data?.post,
        outside: action?.data?.outside,
      };
      return newState;
    }

    case SET_ROUTINGS: {
      const newState = {
        ...state,
        routings: action?.routings,
      };
      return newState;
    }

    case ADJUST_ROUTING: {
      const old = state.routings.filter((r) => r != action.route);
      const remove = state.routings.includes(action.route);
      const routings = [...(remove ? old : [...old, action.route])].sort();
      const newState = { ...state, routings };
      return newState;
    }

    case SET_PART_ROUTING_SUBMIT: {
      const newState = {
        ...state,
        submitting: action.value,
      };
      return newState;
    }

    case ADD_DIP: {
      const old = state.dip?.dips || [];
      const newState = {
        ...state,
        dip: {
          ...state.dip,
          dips: handleAddTemplate(old, "dip"),
        },
      };
      return newState;
    }

    case EDIT_PART_DIP: {
      const newState = {
        ...state,
        dip: {
          ...state.dip,
          dips: updateItemHelper(action.idx, state.dip?.dips, action.data),
        },
      };

      return newState;
    }

    case ADD_POST: {
      const old = state.post?.postFoundryRouting || [];
      const newState = {
        ...state,
        post: {
          ...state.post,
          postFoundryRouting: handleAddTemplate(old, "post"),
        },
      };
      return newState;
    }

    case EDIT_PART_POST: {
      const newState = {
        ...state,
        post: {
          ...state.post,
          postFoundryRouting: updateItemHelper(
            action.idx,
            state.post?.postFoundryRouting,
            action.data
          ),
        },
      };

      return newState;
    }

    case SET_PART_POST: {
      const newState = {
        ...state,
        post: {
          ...state.post,
          postFoundryRouting: action.routes,
        },
      };
      return newState;
    }

    case ADD_OUTSIDE_PROCESS: {
      const old = state.outside?.outsideProcesses || [];
      const newState = {
        ...state,
        outside: {
          ...state.outside,
          outsideProcesses: handleAddTemplate(old, "outside"),
        },
      };
      return newState;
    }

    case EDIT_PART_OUTSIDE: {
      const newState = {
        ...state,
        outside: {
          ...state.outside,
          outsideProcesses: updateItemHelper(
            action.idx,
            state.outside?.outsideProcesses,
            action.data
          ),
        },
      };

      return newState;
    }

    case REMOVE_DIP: {
      const newState = {
        ...state,
        dip: {
          ...state.dip,
          dips: removeItemHelper(action.idx, state.dip?.dips),
        },
      };
      return newState;
    }

    case REMOVE_OUTSIDE_PROCESS: {
      const newState = {
        ...state,
        outside: {
          ...state.outside,
          outsideProcesses: removeItemHelper(
            action.idx,
            state.outside?.outsideProcesses
          ),
        },
      };
      return newState;
    }

    case REMOVE_POST: {
      const newState = {
        ...state,
        post: {
          ...state.post,
          postFoundryRouting: removeItemHelper(
            action.idx,
            state.post?.postFoundryRouting
          ),
        },
      };
      return newState;
    }

    default:
      return state;
  }
}

// PART DIP ARRAY //
export const addPartDips = () => ({
  type: ADD_DIP,
});
export const removePartDip = (idx) => ({
  type: REMOVE_DIP,
  idx,
});
export function updatePartDips(idx, data) {
  return {
    type: EDIT_PART_DIP,
    idx,
    data,
  };
}

// PART POST ARRAY //
export const addPartPost = () => ({
  type: ADD_POST,
});
export const removePartPost = (idx) => ({
  type: REMOVE_POST,
  idx,
});
export function updatePartPost(idx, data) {
  return {
    type: EDIT_PART_POST,
    idx,
    data,
  };
}

export const setPartPostRouting = (routes = []) => ({
  type: SET_PART_POST,
  routes,
});

// PART OUTSIDE ARRAY //
export const addPartOutside = () => ({
  type: ADD_OUTSIDE_PROCESS,
});
export const removePartOutside = (idx) => ({
  type: REMOVE_OUTSIDE_PROCESS,
  idx,
});
export function updatePartOutside(idx, data) {
  return {
    type: EDIT_PART_OUTSIDE,
    idx,
    data,
  };
}

export const loadPartRoutings = (data) => ({
  type: LOAD_ROUTINGS,
  data,
});

export const adjustPartRoutings = (route) => ({
  type: ADJUST_ROUTING,
  route,
});

export function setPartRoutings(routings = []) {
  return {
    type: SET_ROUTINGS,
    routings,
  };
}

export const setPartRoutingSubmit = (value) => ({
  type: SET_PART_ROUTING_SUBMIT,
  value,
});

export const getPartRouting = createSelector(
  (state) => state,
  (state) => state?.partRoutings
);

export const getPartRoutingName = createSelector(
  getPartRouting,
  (partRouting) => ({
    partName: partRouting.partName,
    partNumber: partRouting.partNumber,
  })
);

export const getPartRoutingDetails = createSelector(
  getPartRouting,
  (partRouting) => ({
    partName: partRouting.partName,
    partNumber: partRouting.partNumber,
    material: partRouting.material,
    tree: partRouting.tree,
    ppt: partRouting.ppt,
    weight: partRouting.weight,
    price: partRouting.price,
  })
);

export const getPartRoutings = createSelector(
  getPartRouting,
  (partRouting) => partRouting.routings || []
);

export const getPartRoutingSubmitting = createSelector(
  getPartRouting,
  (partRouting) => partRouting.submitting || false
);

export const getPartRouteByName = createSelector(
  getPartRouting,
  (_, route) => route,
  (partRouting, route) => partRouting[route] || {}
);

// PART DIP //
export const getPartDipInstructions = createSelector(
  getPartRouting,
  (partRouting) => partRouting.dip || {}
);
export const getPartDips = createSelector(
  getPartDipInstructions,
  (dip) => dip.dips || []
);

// PART POST FOUNDRY //
export const getPartPostInstructions = createSelector(
  getPartRouting,
  (partRouting) => partRouting.post || {}
);
export const getPartPostRoutings = createSelector(
  getPartPostInstructions,
  (post) => post.postFoundryRouting || []
);

export const getPartRoutingCreatedAt = createSelector(
  getPartRouting,
  (partRouting) => partRouting.createdAt || ""
);

export const getPartRoutingDays = createSelector(
  getPartRouting,
  (partRouting) => getTotalDays(partRouting) || 0
);

const reduceLead = (tol, curr) => tol + curr.leadTime;
export const getTotalDaysByObject = ({
  wax,
  assembly,
  dip,
  autoclave,
  foundry,
  postFoundry,
}) => {
  const w = wax?.leadTime || 0;
  const a = assembly?.leadTime || 0;
  const d = dip?.dips?.reduce(reduceLead, 0) || 0;
  const at = autoclave?.leadTime || 0;
  const f = foundry?.leadTime || 0;
  const p = postFoundry?.routes?.reduce(reduceLead, 0) || 0;

  const minutes = [w, a, d, at, f, p];
  const totalMinutes = minutes.reduce((tol, curr) => tol + curr, 0);
  const days = totalMinutes / 1440;
  return days;
};
