import React from "react";
import { Stack } from "@mui/material";

import { StyledNavStack, StyledNavButton } from "./styles";

export const SideNavBar = ({
  tabs = [],
  value,
  setValue = () => {},
  EndComponent,
}) => {
  return (
    <StyledNavStack minWidth="150px">
      <Stack gap="5px">
        {tabs?.map(({ id, icon, label }) => (
          <StyledNavButton
            key={label}
            label={label}
            size="small"
            onClick={() => setValue(id)}
            iconProps={{ size: 12 }}
            variant="text"
            active={id === value}
            {...(icon ? { icon } : {})}
          />
        ))}
      </Stack>
      {EndComponent || <div />}
    </StyledNavStack>
  );
};

export default SideNavBar;
