import React from "react";
import { Table, TableContainer } from "@mui/material";

import { HubPagination } from "../HubPagination";
import HubTableHeader from "../HubTable/HubTableHeader/HubTableHeader";
import HubTableBody from "./HubTableBody";

export const HubTableTemplate = ({
  id,
  headerProps = {},
  bodyProps = {},
  meta = {},
  ...rest
}) => {
  return (
    <>
      <TableContainer {...rest}>
        <Table id={id}>
          <HubTableHeader
            includeCheckbox={false}
            includeEnd={false}
            {...headerProps}
            sx={{ backgroundColor: "hubColors.greyLighter" }}
          />
          <HubTableBody {...bodyProps} />
        </Table>
      </TableContainer>
      {meta?.count > 25 ? <HubPagination {...meta} /> : <></>}
    </>
  );
};

export default HubTableTemplate;
