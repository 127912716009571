import React from "react";
import { Chip, Stack, Typography } from "@mui/material";

const DisplayTableContainer = ({ title, count, children }) => {
  return (
    <Stack overflow="hidden">
      <Stack direction="row" alignItems="center">
        <Typography
          variant="caption"
          sx={{ fontWeight: 800, color: "hubColors.mainFocus" }}
        >
          {count || 0}
        </Typography>
        <Typography variant="caption" padding="5px">
          {title}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default DisplayTableContainer;
