import PartDipDetails from "./forms/PartDipDetails";
import PartWaxDetails from "./forms/PartWaxDetails";
import PartFoundry from "./forms/PartFoundry";
import PartPostFoundry from "./forms/PartPostFoundry";
import PartGeneric from "./forms/PartGeneric";

export const PART_SECTIONS = {
  wax: <PartWaxDetails />,
  assembly: <PartGeneric name="Assembly" includeCyle={false} />,
  dip: <PartDipDetails />,
  autoclave: <PartGeneric name="Autoclave" includeCyle={false} />,
  foundry: <PartFoundry />,
  post: <PartPostFoundry />,
  inspection: <PartGeneric name="Inspection" />,
};
