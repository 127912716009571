import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { HubHookFormWrapper, PageContent } from "../../components";
import {
  useGetWorkOrderData,
  usePutAdminCloseWorkOrder,
  usePutAdminUpdateWorkOrder,
} from "../../hooks";
import { StyleDisplayStack } from "./styles";

import WorkOrderMain from "./WorkOrderMain";
import WorkOrderProd from "./WorkOrderProd";
import WorkOrderBar from "./WorkOrderBar";
import WorkOrderNav from "./WorkOrderNav";
import { ConfirmWorkOrderStation } from "../../dialogs";

const formSx = { height: "100%", overflow: "hidden" };

export const WorkOrderDetails = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetWorkOrderData(id);
  const { mutate: putAdminUpdateWorkOrder } = usePutAdminUpdateWorkOrder();
  const { mutate: putAdminCloseWorkOrder } = usePutAdminCloseWorkOrder();

  const [route, setRoute] = useState();
  const [isClosing, setIsClosing] = useState();

  useEffect(() => {
    refetch();
  }, [id]);

  const onFormSubmit = ({ routing }) => {
    // implement validation to previous completed route
    const newRoute = data?.routingOptions?.find((r) => r.id === routing);
    setRoute(newRoute);
  };

  const onClose = () => setRoute(null);
  const handleCloseWorkOrder = () => {
    setIsClosing(true);
    setRoute(true);
  };

  const onConfirm = () => {
    if (isClosing) putAdminCloseWorkOrder(id);
    else {
      const payload = { id, routeId: route.id };
      putAdminUpdateWorkOrder(payload);
    }
  };

  return (
    <PageContent gap="15px" isLoading={isLoading} disablePadding key={id}>
      {data && (
        <HubHookFormWrapper onSubmit={onFormSubmit} sx={formSx}>
          <WorkOrderBar
            workOrder={data?.workOrder}
            onCloseWorkOrder={handleCloseWorkOrder}
            status={data?.status}
          />
          <StyleDisplayStack>
            <WorkOrderNav />
            <WorkOrderProd />
            <WorkOrderMain />
          </StyleDisplayStack>
        </HubHookFormWrapper>
      )}
      <ConfirmWorkOrderStation
        isOpen={!!route}
        isClosing={isClosing}
        onClose={onClose}
        onSubmit={onConfirm}
        route={route}
      />
    </PageContent>
  );
};

export default WorkOrderDetails;
