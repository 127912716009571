import { ROLES } from "./roles";

export const MENU_ITEMS = [
  { id: 1, label: "Customers", path: "/customers" },
  { id: 2, label: "Parts", path: "/parts" },
  { id: 3, label: "Orders", path: "/orders" },
  { id: 4, label: "Work Orders", path: "/works" },
  { id: 5, label: "Displays", path: "/displays" },
  { id: 6, label: "Labor", path: "/labor" },
  { id: 7, label: "Tickets", path: "/tickets" },
];

const displayOnly = (m) => m.id >= 5;
export const getUserMenuItems = (role) => {
  const ITEMS =
    role === ROLES.Generic ? MENU_ITEMS.filter(displayOnly) : MENU_ITEMS;
  return ITEMS;
};
