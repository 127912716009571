// import PartQuoting from "./forms/PartQuoting";
import PartRouting from "./forms/PartRouting";

export const tabs = [
  // {
  //   id: "QUOTE",
  //   icon: "DollarSign",
  //   label: "Quoting",
  // },
  {
    id: "ROUTING",
    icon: "Settings",
    label: "Routing",
  },
  {
    id: "WORKS",
    icon: "Layout",
    label: "Work Orders",
  },
  // {
  //   id: "HISTORY",
  //   icon: "Calendar",
  //   label: "History and Files",
  // },
];

export const PART_SECTIONS = {
  ROUTING: <PartRouting />,
  QUOTE: <></>,
  HISTORY: <></>,
};

export const UpdateMapper = (formData, id) => {
  const sections = [
    "wax",
    "assembly",
    "dip",
    "autoclave",
    "foundry",
    "post",
    "inspection",
  ];

  const mappedData = sections.reduce((acc, section) => {
    if (formData?.[section]) {
      acc[section] = { ...formData[section] };
      delete acc[section].id;
    }
    return acc;
  }, {});

  const details = formData?.details;

  return {
    id,
    details: {
      partName: details?.partName,
      partNumber: details?.partNumber,
      materialId: details?.materialId,
      treeId: details?.treeId,
      partsPerTree: details?.partsPerTree,
      weight: details?.weight,
      lastSentPrice: details?.lastSentPrice,
    },
    ...mappedData,
  };
};
