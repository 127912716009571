import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, TableBody, Chip } from "@mui/material";
import { connect } from "react-redux";

import { useDeletePartById } from "../../hooks";
import { WarningDeleteDialog } from "../../dialogs";
import { getDollarValue } from "../../helpers/currency";
import { getCustomerDataParts } from "../../redux/customerData";
import CustomerPartsContextMenu from "./CustomerPartsContextMenu";
import { PART_STATUS, PART_LABELER } from "../../constants/parts";

const flows = {
  [PART_STATUS.QUOTED]: "blue",
  [PART_STATUS.READY_FOR_PROD]: "green",
  [PART_STATUS.PRODUCTION]: "green",
};

const CustomerPartsBody = ({ parts = [] }) => {
  const [deletingId, setDeletingId] = useState(false);
  const { mutate: deletePartById, isSuccess } = useDeletePartById();

  const navigate = useNavigate();

  const onClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/parts/${id}`);
  };

  const handleClose = () => setDeletingId(false);
  const handleSubmit = () => deletingId && deletePartById(deletingId);

  useEffect(() => {
    isSuccess && handleClose();
  }, [isSuccess]);

  return (
    <>
      <TableBody>
        {parts?.map((c) => (
          <TableRow key={c?.id} onClick={(e) => onClick(e, c?.id)}>
            <TableCell variant="blueBold">{c?.name}</TableCell>
            <TableCell>{c?.tree}</TableCell>
            <TableCell>{c?.weight}</TableCell>
            <TableCell>{c?.partsPerTree}</TableCell>
            <TableCell>{c?.alloy}</TableCell>
            <TableCell>{getDollarValue(c?.lastSentPrice)}</TableCell>
            <TableCell padding="checkbox">
              <CustomerPartsContextMenu
                id={c?.id}
                setDeletingId={setDeletingId}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <WarningDeleteDialog
        isOpen={!!deletingId}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title="You are about to delete this part!"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  parts: getCustomerDataParts(state),
});

export default connect(mapStateToProps)(CustomerPartsBody);
