import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";

import { useHubSnackBar, usePostCreateLabor } from "../../hooks";

import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormWrapper,
} from "../../components";

import { useDialogContext } from "../HubDialogWrapper";
import { useGetWorkOrderRouting } from "../../hooks/react-query/useWorkOrderData";
import CreateLaborContentEmployeeId from "./CreateLaborContentEmployeeId";

export const CreateLaborContent = ({ workOrder, setWorkOrder = () => {} }) => {
  const { addSnackbarSuccess, addSnackbarError } = useHubSnackBar();
  const { data: routeData, isError } = useGetWorkOrderRouting(workOrder);
  const {
    mutate: postCreateLabor,
    isLoading,
    isSuccess,
    data,
  } = usePostCreateLabor();

  const { onClose = () => {} } = useDialogContext();

  const handleClose = () => {
    onClose();
    setWorkOrder(null);
  };

  useEffect(() => {
    if (isError) {
      addSnackbarError({
        title: "Error Retrieving Work Order",
        message: "Please confirm the work order is valid",
      });
      handleClose();
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && data?.id) {
      addSnackbarSuccess({ message: "Labor Ticket Created Successfully" });
      handleClose();
    }
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const payload = {
      routingId: formData.routingId,
      employeeId: formData.employeeId,
    };
    postCreateLabor(payload);
  };

  return (
    <HubDialogContent largePadding>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="35px">
          <Stack gap="10px" alignItems="center">
            <Typography variant="h6">Create a New Labor Ticket</Typography>
            <Typography
              variant="caption"
              sx={{ textAlign: "center", maxWidth: "450px" }}
            >
              You are about to create a new labor ticket for work order{" "}
              <strong>{workOrder}</strong> with a start time of when the ticket
              is created. Select the routing you are about to work on and enter
              your employee ID to continue.
            </Typography>
          </Stack>
          <Stack direction="row" gap="15px">
            <HubHookFormSelect
              name="routingId"
              label="Work Order Routes"
              options={routeData?.options || []}
              defaultValue={routeData?.selected}
              required
            />
            <CreateLaborContentEmployeeId />
          </Stack>
          <HubCancelSubmitGroup
            disabled={isLoading}
            isLoading={isLoading}
            label="Create Ticket"
            overrideCancel={handleClose}
          />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

export default CreateLaborContent;
