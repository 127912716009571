import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { getCustomersMeta, setCustomers } from "../../redux/customers";
import { getUserLocation } from "../../redux/auth";
import { useGetCustomerDetails } from "../../hooks";
import { PageContent } from "../../components";
import { CompaniesTable } from "../../tables";
import { CreateCompany } from "../../dialogs";

import CompaniesBar from "./CompaniesBar";
import CompaniesEmpty from "./CompaniesEmpty";

const Companies = ({ _setCustomers, meta, userLocation }) => {
  const { search } = useLocation();

  const { data, isLoading, isSuccess } = useGetCustomerDetails({
    defaultActive: true,
    defaultLocation: userLocation,
  });
  const hasContacts = meta?.count;

  useEffect(() => {
    isSuccess && _setCustomers(data);
  }, [data, isSuccess, search?.toString()]);

  return (
    <PageContent gap="15px" isLoading={isLoading} white>
      <CompaniesBar />
      {hasContacts ? <CompaniesTable /> : <CompaniesEmpty />}
      <CreateCompany />
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  meta: getCustomersMeta(state),
  userLocation: getUserLocation(state),
});
const mapDispatchToProps = {
  _setCustomers: setCustomers,
};

export const CompaniesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Companies);
