import qs from "qs-lite";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../utils/api";
import { useHubSnackBar } from "../useSnackBar";

const LABOR = "/labor";

const postCreateLabor = (payload) => api.post(LABOR, payload);
export const usePostCreateLabor = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postCreateLabor, {
    onSuccess: () => {
      queryClient.invalidateQueries(LABOR);
      addSnackbarSuccess({ message: "Successfully Created Labor Ticket" });
    },
  });
};

const putUpdateLabor = (payload) => {
  const id = payload.id;
  delete payload.id;
  return api.put(`${LABOR}/${id}`, payload);
};
export const usePutUpdateLabor = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putUpdateLabor, {
    onSuccess: () => {
      queryClient.invalidateQueries(LABOR);
      addSnackbarSuccess({ message: "Successfully Updated Labor Ticket" });
    },
  });
};

const getAllLaborData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${LABOR}?${queryString}`);
};

export const useGetAllLaborData = () => {
  const query = new URLSearchParams(window.location.search);

  const department = query.get("department") || 1;

  const orderBy = query.get("orderBy") || "workOrder";
  const sortOrder = query.get("sortOrder") || "desc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const queryProps = {
    limit,
    offset,
    orderBy,
    sortOrder,
    department,
  };

  return useQuery([LABOR, { ...queryProps }], getAllLaborData);
};

const getLaborData = ({ queryKey }) => api.get(`${LABOR}/${queryKey[1]}`);
export const useGetLaborData = (id) => {
  return useQuery([LABOR, id], getLaborData, {
    retry: false,
    enabled: !!id,
  });
};
