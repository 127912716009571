import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { PageTabTitle, TopNavBar, AuthenticatedUser } from "./components";

import { routes } from "./routes";

const AuthenticatedRoute = ({ authenticate, title, element, admin }) => {
  const location = useLocation();

  if (authenticate) {
    return (
      <AuthenticatedUser location={location}>
        <PageTabTitle title={title} />
        <TopNavBar />
        {element}
      </AuthenticatedUser>
    );
  }

  return (
    <>
      <PageTabTitle title={title} />
      {element}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        {routes?.map((route, key) => (
          <Route
            path={route?.path}
            element={<AuthenticatedRoute {...route} />}
            key={key}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
