import React from "react";
import { Chip, Stack, Typography } from "@mui/material";

import { HubBackButton, HubButton } from "../../components";
import { StyledTitleStack } from "../PartDetails/styles";
import { JOB_STATUSES } from "../../constants";

export const StatusChip = ({ status }) => {
  const chipProps = { variant: "colorTag", sx: { fontSize: 10 } };

  let props = { label: "Not Started", flow: "grey", ...chipProps };
  switch (status) {
    case JOB_STATUSES.IN_PROGRESS:
      props = { label: "In Progress", flow: "green", ...chipProps };
      break;
    case JOB_STATUSES.COMPLETE:
      props = { label: "Completed", flow: "blue", ...chipProps };
    default:
      break;
  }
  return <Chip {...props} />;
};

const WorkOrderBar = ({ workOrder, status, onCloseWorkOrder = () => {} }) => {
  return (
    <StyledTitleStack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubBackButton />
        <Typography variant="caption">{workOrder}</Typography>
        <StatusChip status={status} />
      </Stack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubButton
          variant="outlined"
          size="xsmall"
          color="neutral"
          label="Close Work Order"
          onClick={onCloseWorkOrder}
        />
        <HubButton
          variant="contained"
          size="xsmall"
          label="Save Changes"
          type="submit"
        />
        {/* <PartContextMenu id={id} /> */}
      </Stack>
    </StyledTitleStack>
  );
};

export default WorkOrderBar;
