import React, { useEffect, useState } from "react";
import { TableCell } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { HubHookFormInput, HubHookFormSelect } from "../../components";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";

import LineItemContextMenu from "./LineItemContextMenu";
import { FOUNDRIES } from "../../constants";

const OrderLineItemNewRowV2 = ({
  idx,
  parts = [],
  addNewRow = () => {},
  removeNewRow = () => {},
}) => {
  const [hasEdited, setHasEdited] = useState(false);

  const partItems = parts?.map((p) => ({ id: p.id, label: p.name }));
  const prefix = `newLineItems[${idx}].`;

  const { setValue, getValues, watch } = useFormContext();

  useEffect(() => {
    if (hasEdited) addNewRow();
  }, [hasEdited]);

  const onUpdatePart = (partId) => {
    const part = parts?.find((p) => p?.id === partId);
    setValue(prefix + "price", part?.lastSentPrice);
    setValue(prefix + "margin", part?.margin || 0.33);
    setHasEdited(true);
  };

  const onUpdatePrice = (price) => {
    const partId = getValues(prefix + "partId");
    const part = parts?.find((p) => p?.id === partId);

    if (!part.lastSentPrice) setValue(prefix + "margin", 0.33);
    else {
      const margin = (price * part.margin) / part.lastSentPrice;
      const final = Math.round(margin * 100) / 100;
      setValue(prefix + "margin", final);
    }
    setHasEdited(true);
  };

  const onFieldUpdate = () => setHasEdited(true);
  const defaultLocation = watch("location");

  return (
    <>
      <StyledTableRow key={idx} bgDisabled main={!hasEdited}>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }}>
          <HubHookFormInput
            name={prefix + "customerLine"}
            placeholder="Line"
            onBlur={onFieldUpdate}
            disableHelperText
            required={hasEdited}
          />
        </TableCell>
        <TableCell sx={{ width: 250, minWidth: 250 }}>
          <HubHookFormSelect
            name={prefix + "partId"}
            onChange={(e) => onUpdatePart(e?.target?.value)}
            required={hasEdited}
            options={partItems}
            useDefault={false}
            disableHelperText
          />
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }}>
          <HubHookFormInput
            name={prefix + "quantity"}
            placeholder="1000"
            onBlur={onFieldUpdate}
            disableHelperText
            isNumber
            required={hasEdited}
          />
        </TableCell>
        <TableCell sx={{ width: 125, minWidth: 125 }}>
          <HubHookFormInput
            name={prefix + "price"}
            placeholder="10.00"
            onBlur={(e, value) => onUpdatePrice(value)}
            disableHelperText
            isCurrency
            required={hasEdited}
          />
        </TableCell>
        <TableCell sx={{ minWidth: 125 }}>
          <StyledHubHookFormDatePicker
            name={prefix + "dueDate"}
            placeholder="MM/DD/YYYY"
            onChange={onFieldUpdate}
            required={hasEdited}
          />
        </TableCell>
        <TableCell sx={{ width: 150, minWidth: 150 }}>
          <HubHookFormSelect
            name={prefix + "locationId"}
            onChange={onFieldUpdate}
            required={hasEdited}
            options={FOUNDRIES}
            defaultValue={defaultLocation}
            disableHelperText
          />
        </TableCell>
        <TableCell sx={{ maxWidth: "35px" }}>
          {/* <HubHookFormInput
            name={prefix + "idx"}
            defaultValue={item?.idx}
            disableHelperText
            hidden
          /> */}
          <HubHookFormInput
            name={prefix + "margin"}
            defaultValue={0.33}
            required={hasEdited}
            hidden
          />
          {/* <LineItemContextMenu id={idx} removeItem={removeNewRow} /> */}
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default OrderLineItemNewRowV2;
