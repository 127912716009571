import React, { useEffect, useState } from "react";
import { TableCell, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { getDollarValue } from "../../helpers/currency";
import { HubHookFormInput, HubHookFormSelect } from "../../components";
import { onUpdateDateHelper } from "./helper";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";

import LineItemContextMenu from "./LineItemContextMenu";
import OrderLineItemChip from "./OrderLineItemChip";
import { getDateData } from "../../helpers/dateHelper";

const OrderLineItemRow = ({
  idx,
  item = {},
  part = {},
  disabled = false,
  deletedValues = [],
  updatedItems = [],
}) => {
  const { setValue, getValues, register } = useFormContext();

  const [deleted, setDeleted] = useState(deletedValues.includes(item.id));

  const prefix = `workOrders[${idx}].`;

  const existing = updatedItems.find((u) => u.id === item.id) || {};
  const rowItem = { ...item, ...existing };

  useEffect(() => {
    if (deleted) {
      const addedDeleted = [...new Set([...deletedValues, item.id])];
      setValue("deletedWorkOrders", addedDeleted);
    }
  }, [deleted]);

  useEffect(() => {
    register(prefix + "trees", { required: true });
    register(prefix + "patReq", { required: true });
  }, [register, prefix]);

  useEffect(() => {
    const trees = Math.ceil(rowItem.quantity / part?.partsPerTree);
    const patReq = trees * part?.partsPerTree;

    setValue(prefix + "trees", trees, { shouldDirty: false });
    setValue(prefix + "patReq", patReq, { shouldDirty: false });
  }, [part.id, rowItem.quantity]);

  const onFieldUpdate = () => {
    const preItem = getValues(`workOrders[${idx}]`);
    let updatedItem = { ...item, ...preItem };

    const updatedIndex = updatedItems.findIndex((u) => u.id === item.id);
    updatedIndex > -1
      ? (updatedItems[updatedIndex] = updatedItem)
      : updatedItems.push(updatedItem);

    setValue("updatedWorkOrders", updatedItems);
  };

  const onUpdateQuantity = (quantity) => {
    const trees = Math.ceil(quantity / part?.partsPerTree);
    const patReq = trees * part?.partsPerTree;

    setValue(prefix + "quantity", patReq);
    setValue(prefix + "trees", trees);
    setValue(prefix + "patReq", patReq);
    onFieldUpdate();
  };

  const onUpdateDate = (value, isStart = false) => {
    const { startDate, dueDate } = onUpdateDateHelper(
      value,
      isStart,
      part?.totalDays
    );

    console.log({ part });

    setValue(prefix + "startDate", startDate);
    setValue(prefix + "endDate", dueDate);
    onFieldUpdate();
  };

  if (deleted) return <></>;
  return (
    <>
      <StyledTableRow key={rowItem.id} bgDisabled>
        <TableCell disabled={disabled}>
          <OrderLineItemChip
            state={rowItem?.state}
            label={rowItem?.statusLabel}
          />
        </TableCell>
        <TableCell
          sx={{ minWidth: 100 }}
          variant="blueBold"
          disabled={disabled}
        >
          <Typography variant="caption11" color="inherit">
            {rowItem?.woNumber}
          </Typography>
        </TableCell>
        <TableCell sx={{ minWidth: 100 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">{rowItem?.lotNumber}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + "lotNumber"}
              placeholder="UT240000"
              defaultValue={rowItem?.lotNumber}
              onBlur={() => onFieldUpdate()}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">{rowItem?.quantity}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + "quantity"}
              placeholder="1000"
              defaultValue={rowItem?.quantity}
              onBlur={(e, value) => onUpdateQuantity(value)}
              disableHelperText
              isNumber
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 125 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {getDateData({
                startDate: rowItem?.startDate,
                includeYear: true,
              })}
            </Typography>
          ) : (
            <StyledHubHookFormDatePicker
              name={prefix + "startDate"}
              placeholder="MM/DD/YYYY"
              defaultValue={rowItem?.startDate}
              onChange={(value) => onUpdateDate(value, true)}
              disabled={!part?.id}
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 125 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {getDateData({ startDate: rowItem?.endDate, includeYear: true })}
            </Typography>
          ) : (
            <StyledHubHookFormDatePicker
              name={prefix + "endDate"}
              placeholder="MM/DD/YYYY"
              defaultValue={rowItem?.endDate}
              onChange={(value) => onUpdateDate(value)}
              disabled={!part?.id}
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ maxWidth: "35px" }}>
          <HubHookFormInput
            name={prefix + "id"}
            defaultValue={rowItem?.id}
            disableHelperText
            hidden
          />
          <LineItemContextMenu
            id={idx}
            disabled={disabled}
            removeItem={() => setDeleted(true)}
          />
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default React.memo(OrderLineItemRow);
