import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { tabs } from "./helper";
import { SideNavBar } from "../../components";

export const DisplaysMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const property = query.get("department");

  const setValue = (id) => {
    query.set("department", id);

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  useEffect(() => {
    setValue(property || tabs[0].id);
  }, [property, tabs]);

  const props = { tabs, value: Number(property), setValue };
  return <SideNavBar {...props} />;
};

export default DisplaysMenu;
