import React from "react";

import { useGetDisplayDashboard } from "../../hooks";
import { HubTableTemplate } from "../../components";
import { condition } from "./helper";

const sortable = true;
const items = [
  {
    id: "startDate",
    label: "Start Date",
    sortable,
  },
  //   {
  //     id: "customer",
  //     label: "Customer",
  //   },
  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,
  },
  {
    id: "lotNumber",
    label: "Lot",
  },
  {
    id: "available",
    label: "Available",
  },
  {
    id: "remaining",
    label: "Remaining",
  },
  {
    id: "station",
    label: "Station",
  },
];

const keys = [
  {
    value: "startDate",
    isDate: true,
  },
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    value: "workOrder",
    variant: "blueBold",
  },
  {
    value: "lotNumber",
  },
  {
    value: "available",
    defaultValue: 0,
  },
  {
    value: "remaining",
    defaultValue: 0,
  },
  {
    value: "station",
  },
];

const StandardTable = ({ setWorkOrder = () => {}, defaultLocation }) => {
  const { data } = useGetDisplayDashboard({ labor: false, defaultLocation });

  const onClick = (item) => {
    setWorkOrder(item?.workOrder);
  };

  const modifiedKeys = keys.map((key) =>
    key.value === "workOrder" ? { ...key, onClick } : key
  );
  const headerProps = { items, includeCheckbox: false, includeEnd: false };
  const bodyProps = { items: data?.rows, keys: modifiedKeys, condition };
  const props = { headerProps, bodyProps };

  return <HubTableTemplate id="DisplayTable" {...props} />;
};

export default StandardTable;
