import React from "react";
import { HubActionDialog } from "../HubActionDialog";

export const ConfirmWorkOrderStation = ({
  isOpen = false,
  isClosing = false,
  onClose = () => {},
  onSubmit = () => {},
  route = {},
  ...rest
}) => {
  const description = isClosing ? (
    <span>
      You are about to <strong>close</strong> the work order, this will mark{" "}
      <strong>ALL</strong> routings as complete and <strong>ALL</strong>{" "}
      available quantity of parts as <strong>completed</strong>.
    </span>
  ) : (
    <span>
      You are about to update the current work order routing to{" "}
      <strong style={{ textWrap: "nowrap" }}>{route?.label}</strong>, this will
      complete the routings for any incomplete steps till the new routing,{" "}
      <strong>NOT</strong> including the new routing.
    </span>
  );

  return (
    <HubActionDialog
      onClose={onClose}
      open={isOpen}
      title={isClosing ? "Admin Work Order Close" : "Admin Work Order Update!"}
      description={description}
      handleSubmit={onSubmit}
      submitLabel={isClosing ? "Confirm Close" : "Confirm Update"}
      action="warning"
      {...rest}
    />
  );
};

export default ConfirmWorkOrderStation;
