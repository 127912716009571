import React from "react";
import { Stack } from "@mui/material";

import { HubTableTemplate } from "../../components";
import { useGetDisplayDashboard } from "../../hooks";
import { StyledStackGrid, StyledGridRow } from "./styles";
import DisplayTableContainer from "./DisplayTableContainer";
import { condition } from "./helper";

const sortable = true;
const items = [
  {
    id: "startDate",
    label: "Start Date",
    sortable,
  },
  //   {
  //     id: "customer",
  //     label: "Customer",
  //   },
  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,
  },
  {
    id: "lotNumber",
    label: "Lot",
  },
  {
    id: "available",
    label: "Available",
  },
  {
    id: "remaining",
    label: "Remaining",
  },
  // {
  //   id: "complete",
  //   label: "Approved",
  // },
];

const keys = [
  {
    value: "startDate",
    isDate: true,
  },
  //   {
  //     variant: "blueBold",
  //     value: "customer",
  //     navigate: true,
  //     route: "customers",
  //     id: "customerId",
  //   },
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    variant: "blueBold",
    value: "workOrder",
    // navigate: true,
    // route: "works",
    // id: "workOrder",
  },
  {
    value: "lotNumber",
  },
  {
    value: "available",
    defaultValue: 0,
  },
  {
    value: "remaining",
    defaultValue: 0,
  },
  // {
  //   value: "completed",
  //   defaultValue: 0,
  // },
];

const WaxRoomTable = ({ setWorkOrder = () => {}, defaultLocation }) => {
  const { data } = useGetDisplayDashboard({ labor: false, defaultLocation });

  const onClick = (item) => setWorkOrder(item?.workOrder);

  const modifiedKeys = keys.map((key) =>
    key.value === "workOrder" ? { ...key, onClick } : key
  );

  const headerProps = { items, includeCheckbox: false, includeEnd: false };
  const bodyProps = { keys: modifiedKeys, condition };

  const hasDisks = !!data?.disks?.count;

  return (
    <StyledGridRow>
      <DisplayTableContainer title={data?.wax?.title} count={data?.wax?.count}>
        <HubTableTemplate
          id="WaxDisplayTable"
          headerProps={headerProps}
          bodyProps={{ ...bodyProps, items: data?.wax?.rows }}
        />
      </DisplayTableContainer>
      <StyledStackGrid full={hasDisks}>
        {hasDisks && (
          <DisplayTableContainer
            title={data?.disks?.title}
            count={data?.disks?.count}
          >
            <HubTableTemplate
              id="DisksDisplayTable"
              headerProps={headerProps}
              bodyProps={{ ...bodyProps, items: data?.disks?.rows }}
            />
          </DisplayTableContainer>
        )}
        <DisplayTableContainer
          title={data?.assembly?.title}
          count={data?.assembly?.count}
        >
          <HubTableTemplate
            id="AssemblyDisplayTable"
            headerProps={headerProps}
            bodyProps={{ ...bodyProps, items: data?.assembly?.rows }}
          />
        </DisplayTableContainer>
      </StyledStackGrid>
    </StyledGridRow>
  );
};

export default WaxRoomTable;
