export const PART_STATUS = {
  NOT_QUOTED: "NOT_QUOTED",
  QUOTED: "QUOTED",
  READY_FOR_PROD: "READY_FOR_PROD",
  PRODUCTION: "PRODUCTION",
};

export const PART_LABELER = (name) =>
  name
    ?.toLowerCase()
    ?.split("_")
    ?.map((w) => w[0].toUpperCase() + w.slice(1))
    ?.join(" ");

export const PART_STATUS_OPTIONS = Object.values(PART_STATUS)?.map((stat) => ({
  id: stat,
  label: PART_LABELER(stat),
}));